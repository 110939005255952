<template>
    <div class="content">
        <div class="icon">
            <img src="/build/images/not-found.svg">
        </div>
        <div class="title">
             {{$t('not-found.title')}}
        </div>
        <div class="description narrow">
            {{$t('not-found.description')}}
        </div>
        <div class="add-button">
            <router-link :to="{name: 'dashboard' }" class="btn-tbf blue center"><div class="text">{{$t('not-found.button')}}</div></router-link>
        </div>
    </div>
</template>


<script>

    export default {
        data(){
            return{
            }
        },
        mounted(){
        }
    }
</script>
